<template>
  <div class="register">
      <div class="title">
<!-- <img class="img" src="../assets/img/logo.png" alt="" > -->
<!-- <img src="" alt=""> -->
      </div>
      <div class="registerbox">
          
      <el-row  class="row-bg" >
  <el-col :span="24">
       <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>经营许可证</span>
   
  </div>
  <el-form ref="form" :disabled="formdis" :inline="true" :model="form" label-width="100px" class="form">
 
            <el-form-item label="证件号">
    <el-input v-model="form.j_zjh" ></el-input>
  </el-form-item>
  <el-form-item label="住所">
    <el-input v-model="form.j_zs" ></el-input>
  </el-form-item>
  <el-form-item label="法人代表">
    <el-input v-model="form.j_fddbr" ></el-input>
  </el-form-item>
  <el-form-item label="作物种类" >
            <el-input placeholder="" v-model="form.j_zwzl" class="input-with-select">
            
            </el-input>
            </el-form-item>

  <el-form-item label="品种">
    <el-input v-model="form.j_pz"></el-input>
  </el-form-item>
  <el-form-item label="生产地点" >
    <el-input v-model="form.j_scdd"></el-input>
  </el-form-item>
  <el-form-item label="有效期" prop="j_yxq">
      <el-date-picker
      v-model="form.j_yxq"
      @change="change"
      value-format="yyyy-MM-dd"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
    <!-- <el-input v-model="form.j_yxq"></el-input> -->
  </el-form-item>
  <el-form-item label="发证机关" prop="j_fzdw">
    <el-input v-model="form.j_fzdw"></el-input>
  </el-form-item>
  
   <el-divider></el-divider>
   <!-- <el-form-item label="单位简介" prop="j_dwjj">
       <div v-html="form.j_dwjj"></div>
    
  </el-form-item>  -->
 
        </el-form>
        <!-- <el-form-item> -->
            <div style="text-align:center;">
                <el-button type="primary" @click="onSubmit">{{button}}</el-button>
                <!-- <el-button @click="tologin">取消</el-button> -->
            </div>
                
            <!-- </el-form-item> -->
</el-card>
        
      



  </el-col>
  
</el-row>
</div>
     
  </div>
</template>

<script>
import { regionDataPlus,CodeToText  } from 'element-china-area-data'

export default {
    data(){
        return{
            formdis:true,
            button:"修改信息",
            nature:[
                {
                    label:"企业",
                    value:1

                },
                {
                    label:"合作社",
                    value:2

                },
                {
                    label:"种植户",
                    value:3

                },
            ],
           
            options: regionDataPlus,
            selectedOptions: [],
            select:'',
            input3:'',
            licence:false,
            organization:false,
            legal:false,
            city:'',
            qyclass:[
               
            ],
           qyclassSel:[
                {
                    name:"生产",
                    id:"1"
                },
                 {
                    name:"加工",
                    id:"2"
                },
                 {
                    name:"销售",
                    id:"3"
                },
           ],
            form:{
                j_zjh:'',
                j_zs:'',
                j_fddbr:'',
                j_zwzl:'',
                j_pz:'',
                j_scdd:'',
                j_yxq:'',
                j_fzdw:'',




            }

        }
    },
    created(){
        this.getUserInfo()
    },
    methods:{
        change(e){
            console.log(e)
        },
        changeData(data) {
  return data ? new Date(data.replace(/-/g, '/')) : ''
        },
        handleChange(e){
            console.log(CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]])
            // CodeToText[e[0]]
            this.city=CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]]
        },
        async getUserInfo(){
                const {data:res} =await this.$http.post("getjyxk")
                this.form=res.data
                console.log(this.changeData(this.form.j_yxq))
                if(this.changeData(this.form.j_yxq)=="Invalid Date"){
                    this.form.j_yxq=""
                }
                
               
              
                // this.qyclass
               
                console.log(this.form)
        },
        async onSubmit(){
             if(this.formdis==true){
                 this.formdis=false
          this.button="提交修改信息"
             }else{
                const {data:res} = await this.$http.post("editjyxk",this.form)
                if(res.code==0){
                    this.formdis=true
                    this.button="修改信息"
                    this.$message.success("修改成功")
                    this.getUserInfo()
                }else{
                    this.$message.error("修改失败")
                }
             }
          

            
        },
        tologin(){
            this.$router.push('/login')
        },
        isgeti(e){
            if(e==3){
                this.licence=true
                this.organization=true
                this.legal=true
            }else{
                this.licence=false
                this.organization=false
                this.legal=false
            }
        }
    }

}
</script>

<style lang="less" >
.is-disabled .el-input__inner {
    color: #606266!important;
    background-color: #fff!important;
}
.el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner {
    color: #FFFFFF;
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-box-shadow: -1px 0 0 0 #74bcff;
    box-shadow: -1px 0 0 0 #74bcff;
}


// .form{
//     background-color: #fff;
//     width: 1200px;
    
// }
.danwei{
    width: 200px;
}
// .box-card {
//     width: 1200px;
//   }
.title{
    text-align: center;
}
.img {
        height: 114px;
    }

.el-select .el-input {
    width: 200px;
  }
  .el-form-item{
      width: 560px;
      margin-bottom: 15px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
//  .register {
//         height: 100%;
//         background: url() bottom center no-repeat;
//         background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
//         background-image: linear-gradient(0deg,#30cfd0 0,#330867);
//         // background-image: ;
//         // url("../assets/img/zz.png"),
//         background-size: 100% 100%;
//     }
    .jianjie{
        width: 800px;
    }
    .jian{
        width: 600px;
    }
    
</style>